$primary-color: #27272a;
$primary-color-dark: #e5e5e5;

$secondary-color: #ffffff;
$secondary-color-dark: #353b4a;

$accent-color: #e01313;
$accent-color-dark: #e24646;

$bg-color: #ffffff;
$bg-color-dark: #282c35;

$text-color: $primary-color;
$text-color-dark: $primary-color-dark;

$text-link-color: #0366d6;
$text-link-color-dark: #4a9fff;

$logo-bg-color: $primary-color;
$logo-bg-color-dark: $secondary-color-dark;

$logo-color: $secondary-color;
$logo-color-dark: $primary-color-dark;

$card-bg-color: $secondary-color;
$card-bg-color-dark: $secondary-color-dark;

$quote-bg-color: #ffec69;
$quote-bg-color-dark: #4f3b3b;

$text-color: #27272a;
$placeholder-color: darkgray;
$post-content-image-block-background: #dedede;
$post-content-quote-block-background: #ffec69;
$active-color: #139fe0; // #e01313

$container-max-width: 700px;
$left-sidebar-width: 245px;
$right-sidebar-width: 225px;

$post-text-font-size: 1rem;
$post-title-font-size: 1.8rem;
$post-content-block-margin: 15px;
$post-text-line-height: 1.65rem;
