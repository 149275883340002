@import "variables";

.writer {
  .link-tool__content--rendered {
    background: rgb(59, 72, 101);
    border: none;
    border-radius: 10px;
    color: #fff !important;
  }

  .inline-code {
    background: rgb(59 72 101);
        color: #fff;
        padding: 3px 4px;
        border-radius: 5px;
        margin: 0 1px;
        font-family: inherit;
        font-size: 0.86em;
        font-weight: 500;
        letter-spacing: 0.3px;
  }

  mark.cdx-marker {
    background: rgb(72 131 116);
    color: #fff;
    padding: 3px 0;
  }
  
  margin-left: -25px;

  .cdx-personality {
    border: 0px !important;
    background: #3b4865 !important;
  }

  .ce-toolbar__content {
    max-width: 100% !important;
  }

  .codex-editor--narrow .ce-block--focused {
    margin: 0 !important;
    padding: 0 !important;
  }

  .codex-editor--narrow .codex-editor__redactor {
    margin: 0 !important;
    padding: 0 !important;
  }

  .ce-picker {
    position: relative;

    .ce-picker__icons {
      position: absolute;
      right: 0px;
      width: 30px;
      flex-wrap: wrap;
      top: 5px;
      color: #388ae5;
      display: flex;
      justify-content: flex-end;

      .ce-picker__icon {
        display: none;
        width: 16px;
        height: 16px;

        &:not(:last-child) {
          margin-bottom: 8px;
        }

        &.ce-picker__icon--active {
          display: inline-block;
        }
      }
    }
  }

  .cdx-block {
    font-size: $post-text-font-size;
    line-height: $post-text-line-height;
  }

  .cdx-block, .ce-header {
    padding: 0 !important;
    margin: 0 !important;
    margin-bottom: $post-content-block-margin !important;
    line-height: $post-text-line-height !important;
  }

  .ce-delimiter.cdx-block::before {
    margin-top: 30px;
    margin-bottom: 30px;

    &::before {
      display: block;
      content: '***';
      font-size: $post-text-font-size * 2;
      line-height: 1em;
      height: .6em;
      letter-spacing: .2em;
      text-align: center;
    }
  }

  .image-tool.image-tool--withBackground .image-tool__image {
    background: $post-content-image-block-background !important;
  }

  .cdx-list {
    padding-left: 25px !important;
  }

  .image-tool__image-picture {
    width: 100%;
  }

  .cdx-embed-block {
    iframe {
      margin: 0 auto;
      display: block;

      &[data-loading='true'] {
        display: none !important;
      }
    }

    &__caption {
      margin-top: 10px;
    }
  }

  .ce-toolbar__settings-btn svg, .ce-toolbar__plus svg {
    color: $placeholder-color;
  }

  .cdx-settings-button--picker svg {
    width: 16px;
    height: 16px;
  }


  .ce-toolbar__settings-btn {
    background: transparent !important;

    svg {
      width: 13px;
      height: 13px;
    }
  }

  .ce-toolbar .ce-toolbox {
    left: 60px;
    top: 2px;
  }

  .ce-toolbar__plus {
    top: 1px;
    left: 25px;
    width: auto;
    height: auto;

    svg {
      width: 14px;
      height: 14px;
    }
  }

  .ce-toolbar__actions {
    left: 0px;
    right: auto !important;
    top: 5px;
    transform: none !important;
  }

  .ce-settings {
    right: -99px;
  }

  .ce-block__content {
    max-width: 100% !important;
    padding: 0px 26px;
  }

  @media all and (max-width: 650px) {
    .ce-toolbar__actions {
      left: 20px;
      top: 8px
    }

    .ce-toolbar {
      margin-left: 8px;
      margin-right: 8px;
      padding: 3px 10px;
    }
  }

  @media all and (max-width: $container-max-width) {

    @media all and (min-width: 651px) {
      .ce-toolbar__actions {
        left: 10px;
      }

      .ce-toolbar__plus {
        top: 25px;
        left: 12px;
      }
    }
  }

  .ce-block__content a {
    color: var(--text-link-color);
    text-decoration: underline;
  }

  .cdx-list__item {
    padding: 0px;
  }
}
